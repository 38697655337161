export const settings = `
<div style="display:flex; flex-direction:column; height:100vh; width: 100%">
   <div class="react-loading-skeleton"
      style=" width:10%;height:1rem;"></div>
   <div class="react-loading-skeleton"
      style="width:25%; height:1.5rem; margin-top:0.75rem;"></div>
   <div style="display:flex; flex-direction:row; justify-content: flex-start; width: 100%; margin-top:1.25rem;height:5%; gap:0.5rem;">
      <div class="react-loading-skeleton" style="width:40%;"></div>
      <div class="react-loading-skeleton"
         style="width:9%; height:67%; align-self:flex-end; margin-left: auto;"></div>
      <div class="react-loading-skeleton"
         style="width:9%; height:67%; align-self:flex-end"></div>
   </div>
   <div 
      style="display:flex; flex-direction:row; justify-content: flex-start; width: 100%; border-top:1px solid #D8D8D8; border-bottom:1px solid #D8D8D8; align-items: center;height:7%; margin-top:0.75rem; margin-bottom:1.5rem;">
      <div class="react-loading-skeleton"
         style=" width:34%; height:60%;"></div>
   </div>
   <div class="react-loading-skeleton" style="height:4%;"></div>
   <div 
      style="display:flex; flex-direction:row; width: 100%; height:7%; align-items:center; border-bottom: 1px solid #DDDDDD; justify-content:space-between;">
      <div class="react-loading-skeleton"
         style="margin-left:1rem; margin-right:10rem; width:10%; height:67%"></div>
      <div class="react-loading-skeleton"
         style="margin-right:6.25rem; width:3%; height:67%"></div>
      <div class="react-loading-skeleton"
         style="margin-right:13rem; width:3%;  height:67%"></div>
      <div style="display:flex; flex-direction:row; align-items:center; width: 22%; height: 100%">
         <div class="react-loading-skeleton"
            style=" width:30%; height:67%"></div>
         <div class="react-loading-skeleton"
            style="margin-left:1rem;  width:65%; height:67%"></div>
      </div>
      <div class="react-loading-skeleton"
         style="margin-left:14.75rem; width:9%; height:67%; margin-right:1.25rem;"></div>
   </div>
   <div 
      style="display:flex; flex-direction:row; width: 100%; height:7%; align-items:center; border-bottom: 1px solid #DDDDDD; justify-content:space-between;">
      <div class="react-loading-skeleton"
         style="margin-left:1rem; margin-right:10rem; width:10%; height:67%"></div>
      <div class="react-loading-skeleton"
         style="margin-right:6.25rem; width:3%; height:67%"></div>
      <div class="react-loading-skeleton"
         style="margin-right:13rem; width:3%;  height:67%"></div>
      <div style="display:flex; flex-direction:row; align-items:center; width: 22%; height: 100%">
         <div class="react-loading-skeleton"
            style=" width:30%; height:67%"></div>
         <div class="react-loading-skeleton"
            style="margin-left:1rem;  width:65%; height:67%"></div>
      </div>
      <div class="react-loading-skeleton"
         style="margin-left:14.75rem; width:9%; height:67%; margin-right:1.25rem;"></div>
   </div>
   <div 
      style="display:flex; flex-direction:row; width: 100%; height:7%; align-items:center; border-bottom: 1px solid #DDDDDD; justify-content:space-between;">
      <div class="react-loading-skeleton"
         style="margin-left:1rem; margin-right:10rem; width:10%; height:67%"></div>
      <div class="react-loading-skeleton"
         style="margin-right:6.25rem; width:3%; height:67%"></div>
      <div class="react-loading-skeleton"
         style="margin-right:13rem; width:3%;  height:67%"></div>
      <div style="display:flex; flex-direction:row; align-items:center; width: 22%; height: 100%">
         <div class="react-loading-skeleton"
            style=" width:30%; height:67%"></div>
         <div class="react-loading-skeleton"
            style="margin-left:1rem;  width:65%; height:67%"></div>
      </div>
      <div class="react-loading-skeleton"
         style="margin-left:14.75rem; width:9%; height:67%; margin-right:1.25rem;"></div>
   </div>
   <div 
      style="display:flex; flex-direction:row; width: 100%; height:7%; align-items:center; border-bottom: 1px solid #DDDDDD; justify-content:space-between;">
      <div class="react-loading-skeleton"
         style="margin-left:1rem; margin-right:10rem; width:10%; height:67%"></div>
      <div class="react-loading-skeleton"
         style="margin-right:6.25rem; width:3%; height:67%"></div>
      <div class="react-loading-skeleton"
         style="margin-right:13rem; width:3%;  height:67%"></div>
      <div style="display:flex; flex-direction:row; align-items:center; width: 22%; height: 100%">
         <div class="react-loading-skeleton"
            style=" width:30%; height:67%"></div>
         <div class="react-loading-skeleton"
            style="margin-left:1rem;  width:65%; height:67%"></div>
      </div>
      <div class="react-loading-skeleton"
         style="margin-left:14.75rem; width:9%; height:67%; margin-right:1.25rem;"></div>
   </div>
   <div 
      style="display:flex; flex-direction:row; width: 100%; height:7%; align-items:center; border-bottom: 1px solid #DDDDDD; justify-content:space-between;">
      <div class="react-loading-skeleton"
         style="margin-left:1rem; margin-right:10rem; width:10%; height:67%"></div>
      <div class="react-loading-skeleton"
         style="margin-right:6.25rem; width:3%; height:67%"></div>
      <div class="react-loading-skeleton"
         style="margin-right:13rem; width:3%;  height:67%"></div>
      <div style="display:flex; flex-direction:row; align-items:center; width: 22%; height: 100%">
         <div class="react-loading-skeleton"
            style=" width:30%; height:67%"></div>
         <div class="react-loading-skeleton"
            style="margin-left:1rem;  width:65%; height:67%"></div>
      </div>
      <div class="react-loading-skeleton"
         style="margin-left:14.75rem; width:9%; height:67%; margin-right:1.25rem;"></div>
   </div>
   <div 
      style="display:flex; flex-direction:row; width: 100%; height:7%; align-items:center; border-bottom: 1px solid #DDDDDD; justify-content:space-between;">
      <div class="react-loading-skeleton"
         style="margin-left:1rem; margin-right:10rem; width:10%; height:67%"></div>
      <div class="react-loading-skeleton"
         style="margin-right:6.25rem; width:3%; height:67%"></div>
      <div class="react-loading-skeleton"
         style="margin-right:13rem; width:3%;  height:67%"></div>
      <div style="display:flex; flex-direction:row; align-items:center; width: 22%; height: 100%">
         <div class="react-loading-skeleton"
            style=" width:30%; height:67%"></div>
         <div class="react-loading-skeleton"
            style="margin-left:1rem;  width:65%; height:67%"></div>
      </div>
      <div class="react-loading-skeleton"
         style="margin-left:14.75rem; width:9%; height:67%; margin-right:1.25rem;"></div>
   </div>
   <div 
      style="display:flex; flex-direction:row; width: 100%; height:7%; align-items:center; border-bottom: 1px solid #DDDDDD; justify-content:space-between;">
      <div class="react-loading-skeleton"
         style="margin-left:1rem; margin-right:10rem; width:10%; height:67%"></div>
      <div class="react-loading-skeleton"
         style="margin-right:6.25rem; width:3%; height:67%"></div>
      <div class="react-loading-skeleton"
         style="margin-right:13rem; width:3%;  height:67%"></div>
      <div style="display:flex; flex-direction:row; align-items:center; width: 22%; height: 100%">
         <div class="react-loading-skeleton"
            style=" width:30%; height:67%"></div>
         <div class="react-loading-skeleton"
            style="margin-left:1rem;  width:65%; height:67%"></div>
      </div>
      <div class="react-loading-skeleton"
         style="margin-left:14.75rem; width:9%; height:67%; margin-right:1.25rem;"></div>
   </div>
   <div 
      style=" display:flex; flex-direction:row; width: 100%;height:7%; align-items:center; border-bottom: 1px solid #000000; justify-content:space-between;">
      <div class="react-loading-skeleton"
         style="margin-left:1rem; margin-right:10rem; width:10%; height:67%"></div>
      <div class="react-loading-skeleton"
         style="margin-right:6.25rem; width:3%; height:67%"></div>
      <div class="react-loading-skeleton"
         style="margin-right:13rem; width:3%;  height:67%"></div>
      <div style="display:flex; flex-direction:row; align-items:center; width: 22%; height: 100%">
         <div class="react-loading-skeleton"
            style=" width:30%; height:67%"></div>
         <div class="react-loading-skeleton"
            style="margin-left:1rem;  width:65%; height:67%"></div>
      </div>
      <div class="react-loading-skeleton"
         style="margin-left:14.75rem; width:9%; height:67%; margin-right:1.25rem;"></div>
   </div>
   <div class="react-loading-skeleton"
      style="margin-top:0.5rem; width:30%; height:5%; align-self:center;"></div>
</div>
`;
